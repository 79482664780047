@import "../../../data/styles.css";

.snippet {
	mix-blend-mode: normal;
	border-radius: 20px;
	height: 100%;
}

.snippet-container {
	width: 100%;
}

.snippet-logo {
	flex: 1;
	max-width: 324px;
	height: 216px;
	cursor: pointer;
	margin: auto;
}

.snippet-logo img {
	width: 100%;
	height: 100%;
	border-radius: 5%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	object-fit: scale-down;
	background-color: #f1f1f1;
}

.snippet-title {
	font-family: var(--secondary-font);
	color: var(--primary-color);
	font-size: 16px;
	padding-top: 15px;
	margin-bottom: 1em;
	font-weight: 700;
	text-align: center;
}

.snippet-description {
	color: var(--primary-color);
	font-size: 14px;
	font-weight: 700;
	text-align: justify;
}

@media (max-width: 600px) {
	.snippet {
		height: 350px;
		padding-left: 10px;
	}
}
