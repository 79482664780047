@import "../../../data/styles.css";

.socials {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}

.socials-title {
	color: var(--primary-color);
	font-family: var(--secondary-font);
	font-size: 20px;
	font-weight: 500;
	width: 100%;
	text-align: left;
}

.socials-credits {
	flex-basis: 50%;
	padding-bottom: 25px;
}

.socials-socials {
	display: flex;
	justify-content: left;
	align-items: center;
}

.socials-social-icon {
	padding-right: 20px;
	font-size: 25px;
	transition: color 0.2s ease-in-out;
	color: var(--theme-font-color);
}

.socials-social-icon:hover {
	color: var(--primary-color);
}

.socials-credits-text {
	justify-content: flex-end;
	color: var(--tertiary-color);
	font-size: 14px;
	text-align: right;
}

@media (max-width: 600px) {
	.socials {
		flex-direction: column;
		height: 85px;
	}
}
