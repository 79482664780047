.photos-container {
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 15px;
	justify-content: center;
}

.photo {
	width: calc(100% / 4);
	box-sizing: border-box;
	padding: 10px;
	overflow: hidden;
	width: 208px;
	height: 249px;
}

.photo img {
	width: 100%;
	height: 100%;
	border: 1px solid rgba(0, 0, 0, 0.25);
	border-radius: 5%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	object-fit: cover;
	cursor: pointer;
}

@media (max-width: 1024px) {
	.photo {
		width: calc(100% / 4);
	}
}

@media (max-width: 600px) {
	.photo {
		width: calc(100% / 2);
	}
}

@media (max-width: 400px) {
	.photo {
		width: calc(100%);
	}
}
