@import "../../../data/styles.css";

.project {
	mix-blend-mode: normal;
	border-radius: 20px;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.project a {
	text-decoration: none;
}

.project-container {
	padding: 30px;
	flex: 1;
}

.project-logo {
	flex: 1.06;
	width: 509px;
	height: 352px;
	cursor: pointer;
}

.project-logo img {
	width: 100%;
	height: 100%;
	border: 1px solid rgba(0, 0, 0, 0.25);
	border-radius: 5%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	object-fit: cover;
}

.project-title {
	font-family: var(--secondary-font);
	color: var(--primary-color);
	font-size: 18px;
	padding-top: 15px;
	margin-bottom: 1em;
	font-weight: 700;
}

.project-tags-container {
	display: flex;
	flex-wrap: wrap;
}

.project-tag {
	background-color: var(--theme-background-color);
	color: var(--theme-font-color);
	font-family: var(--secondary-font);
	padding: 5px;
	border-radius: 5px;
	margin-right: 10px;
	margin-bottom: 1em;
	font-size: 12px;
	font-weight: 500;
}

.project-period {
	color: var(--secondary-color);
	font-size: 12px;
}

.project-description {
	color: var(--secondary-color);
	font-size: 16px;
}

.project-link {
	display: flex;
	align-items: center;
	font-size: 14px;
	padding-top: 20px;
}

.project-link-text {
	padding: 5px 20px;
	font-weight: 700;
	background-color: var(--theme-font-color);
	color: #fff;
	border-radius: 5px;
	cursor: pointer;
}

.project-link-text-close {
	padding: 5px 20px;
	font-weight: 700;
	background-color: var(--theme-font-color);
	color: #fff;
	border-radius: 5px;
	opacity: 0.5;
}

@media (max-width: 600px) {
	.project {
		height: 500px;
	}
}

@media (max-width: 400px) {
	.project {
		flex-direction: column;
		height: 100%;
	}
}
