@import "../../../data/styles.css";

.all-lens-container {
	display: flex;
	flex-wrap: wrap;
	padding-top: 30px;
	padding-bottom: 30px;
	justify-content: center;
}

.all-lens-project {
	width: calc(100% / 4);
	box-sizing: border-box;
	padding: 10px;
	overflow: hidden;
	width: 248px;
	height: 308px;
	cursor: pointer;
}

.all-lens-project img {
	width: 100%;
	height: 100%;
	border: 1px solid rgba(0, 0, 0, 0.25);
	border-radius: 5%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	object-fit: cover;
}

@media (max-width: 600px) {
	.all-lens-project {
		width: calc(100% / 2);
	}
}

@media (max-width: 400px) {
	.all-lens-project {
		width: calc(100%);
	}
}
