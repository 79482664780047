@import "../../../data/styles.css";

.nav-container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin: 0;
	margin-right: 20%;
}

.navbar {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 3vh;
	z-index: 999;
}

.nav-background {
	width: 224px;
	height: 40px;
	padding-left: 0%;
	padding-right: 0%;
	background: #fff;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 40px;
}

.nav-list {
	display: flex;
	justify-content: space-between;
	list-style: none;
	align-items: center;
	margin-left: -16px;
	margin-right: 20px;
	margin-top: 11px;
}

.nav-item {
	font-weight: bold;
	font-size: 80%;
}

.nav-item.active a {
	color: var(--theme-color) !important;
}

.nav-item a {
	text-decoration: none;
	color: var(--primary-color);
}

.nav-item a:hover {
	color: var(--theme-color);
	transition: color 0.3s ease-in-out;
}

@media (max-width: 1600px) {
	.nav-container {
		margin-right: 15%;
	}
}

@media (max-width: 1450px) {
	.nav-container {
		margin-right: 10%;
	}
}

@media (max-width: 1024px) {
	.nav-container {
		margin-right: 5%;
	}
}

@media (max-width: 600px) {
	.nav-container {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0;
	}

	.navbar {
		margin-top: 1%;
		margin-left: 30%;
		margin-right: 25%;
		width: 80%;
		font-size: 80%;
		position: fixed;
		z-index: 999;
		top: 5vh;
	}

	.nav-background {
		width: 80%;
		height: 40px;
	}

	.nav-item {
		font-weight: bold;
	}
}
