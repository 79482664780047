.all-skills-container {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	padding-top: 30px;
	padding-bottom: 30px;
	margin-left: -35px;
	margin-right: -35px;
}

.all-skills-project {
	width: calc(100% / 6);
	box-sizing: border-box;
	padding: 10px;
	overflow: hidden;
}

.skill-container {
	width: 100%;
}

.skill-logo {
	flex: 1;
	width: 50px;
	height: 50px;
	margin: auto;
	background-color: var(--theme-background-color);
	border-radius: 50%;
	padding: 20px;
}

.skill-logo img {
	width: 100%;
	height: 100%;
	object-fit: scale-down;
}

.skill-title {
	color: var(--primary-color);
	font-family: var(--primary-font);
	font-size: 14px;
	margin-top: 15px;
	margin-bottom: 1em;
	font-weight: 700;
	text-align: center;
}

@media (max-width: 600px) {
	.all-skills-project {
		width: calc(100% / 2);
	}
}
