@import "../../../data/styles.css";

.all-pictures-container {
	display: flex;
	flex-wrap: wrap;
	padding-top: 15px;
	justify-content: center;
	align-items: center;
}

.album-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

.album-title {
	color: var(--primary-color);
	font-family: var(--secondary-font);
	font-size: 20px;
	font-weight: 500;
	width: 100%;
	text-align: center;
}

.album-description {
	color: var(--primary-color);
	font-size: 14px;
	margin-left: 15px;
	margin-top: 10px;
	font-weight: 700;
	text-align: left;
	padding-top: 20px;
	max-width: 820px;
}
