.about-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.about-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
	z-index: 999;
}

.about-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.about-main {
	display: flex;
}

.about-right-side {
	flex-basis: 900px;
}

.about-title {
	width: 100% !important;
	margin-bottom: 15px;
}

.about-subtitle {
	font-family: var(--primary-font);
	font-weight: 700;
	width: 80% !important;
	margin-top: 0px !important;
}

.about-left-side {
	display: flex;
	flex-direction: column;
	flex-basis: 300px;
}

.about-image-container {
	width: 370px;
}

.about-image-wrapper {
	display: inline-block;
	overflow: hidden;
	width: 100%;
	border-radius: 10%;
}

.about-image-wrapper img {
	display: block;
	width: 100%;
}

.about-socials {
	padding-bottom: 25px;
}

.about-info {
	flex-basis: auto;
}

.section-title {
	padding-top: 40px;
	color: var(--theme-font-color);
	font-family: var(--secondary-font);
	font-size: 30px;
	font-weight: 700;
	width: 100%;
	text-align: center;
}

.section-description-about {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: var(--primary-color);
	font-size: 14px;
	margin-left: 15px;
	margin-top: 10px;
	font-weight: 700;
	text-align: left;
	padding-top: 20px;
}

.inner-section-description-about {
	max-width: 820px;
}

@media (max-width: 1024px) {
	.about-logo {
		z-index: 999;
	}

	.about-main {
		flex-direction: column;
	}

	.about-left-side {
		flex-basis: 100%;
		order: 1;
	}

	.about-right-side {
		flex-basis: 100%;
		order: 2;
	}

	.about-image-container {
		padding-bottom: 40px;
	}
}

@media (max-width: 600px) {
	.about-container {
		padding-top: 60px;
	}

	.about-image-container {
		width: 80%;
	}
}
