@import "../../../data/styles.css";

.work {
	mix-blend-mode: normal;
	border-radius: 20px;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
}

.work a {
	text-decoration: none;
}

.work-container {
	flex: 5;
	text-align: left;
}

.work-logo {
	flex: 1;
	width: 50px;
	height: 50px;
}

.work-logo img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.work-company {
	color: var(--primary-color);
	font-size: 18px;
	margin-bottom: 0.1em;
	font-weight: 700;
}

.work-position-period-container {
	display: flex;
	flex-direction: row;
}

.work-position {
	color: var(--primary-color);
	font-size: 14px;
	margin-top: 10px;
	font-weight: 700;
	text-align: left;
}

.work-period {
	color: var(--secondary-color);
	font-size: 14px;
	margin-left: 4px;
	margin-top: 10px;
	font-weight: 700;
	text-align: left;
}

.work-description {
	color: var(--primary-color);
	font-size: 14px;
	margin-left: 15px;
	margin-top: 10px;
	font-weight: 700;
	text-align: left;
}

@media (max-width: 820px) {
	.work-position-period-container {
		display: flex;
		flex-direction: column;
	}
}
